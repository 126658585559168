@use "~styles/_styleGuideFont" as *;
@use "~styles/_styleGuideColour" as colours;

.asosWorldBox {
  border: 2px solid colours.$asosWorldPurpleMain;
  padding: 16px;
}

.description {
  @extend %leavesden3;

  span {
    display: inline-block;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.date {
  @extend %london4;

  align-items: center;
  display: flex;
  margin-top: 12px;
  position: relative;
  text-transform: uppercase;

  &::before {
    content: "";
    background-color: colours.$asosWorldPurpleMain;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    height: 6px;
    margin-right: 6px;
    width: 6px;
  }
}

.icon {
  margin-bottom: 6px;
}
