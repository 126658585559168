@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.container {
  width: 100%;
  background-color: colours.$lightRed;
  padding: 16px;
  margin-top: 12px;
  box-sizing: border-box;
  display: flex;

  h2 {
    @extend %london2;
  }

  p {
    @extend %leavesden2;

    margin-top: 4px;
  }

  button {
    @extend %london2;

    margin-top: 4px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    font-family: inherit;
  }
}

.textContainer {
  margin-left: 16px;
}
