@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont" as *;

.productPromo:not(:empty) {
    margin-top: 20px;
}

.hidden {
    display: none;
}

.promoBox {
    background-color: colours.$lightBlue;
    display: flex;
    margin-top: 20px;
}

.promoText {
    @extend %leavesden3;

    padding: 12px;
    font-style: normal;
    text-align: left;
}

.icon {
    margin-left: 16px;
    margin-top: 12px;
}

.discountCode {
    font-weight: bold;
}

.visuallyHidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }