@use "~styles/_styleGuideFont";

.main {
  color: var(--font-colour);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
}

.errorMessage {
  padding: 24px;
  text-align: center;
}

.face {
  display: block;
  height: 88px;
  margin: 0 auto;
  max-width: 100%;
  width: 88px;
}

.heading {
  @extend %london1;

  letter-spacing: 5.6px;
  margin: 20px;
  text-transform: uppercase;
}

.paragraph {
  @extend %leavesden1;

  margin: 0 auto;
  max-width: 480px;
}
