@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";
@use "~styles/_viewport" as viewports;

.container {
  border-top: 8px solid colours.$grey20;
  display: flex;
  margin: 0 -10px;
  width: calc(100% + 20px);
}

a {
  &.link {
    @extend %leavesden2;

    padding: 8px 16px;
    text-decoration: underline;
  }

  .icon {
    display: inline-block;
    margin-left: 4px;
  }
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .container {
    border-top: none;
    margin: 0;
    width: unset;
  }

  a {
    &.link {
      padding: 20px 0 0;
    }
  }
}