@use "~styles/_viewport" as viewports;

.titleContainer {
  position: unset;
}

@media (min-width: viewports.$breakpointSmallMediumScreen) {
  .titleContainer {
    position: relative;
  }
}
