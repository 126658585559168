@use "~styles/_styleGuideColour"as colours;
@use "~styles/_styleGuideFont";
@use "../viewport"as viewports;

.productCode {
  @extend %london3;

  color:colours.$grey70;
  padding-top: 16px;
}

.label {
    padding-right: 12px;
    text-transform: uppercase;
}

@media (max-width: viewports.$breakpointMobileMaxWidth) {
  .productCode {
    margin-bottom: 16px;
  }
}