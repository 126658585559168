@use "~styles/_styleGuideFont" as fonts;
@use "~styles/_styleGuideColour" as colours;

.container {
  border: 2px solid colours.$amber;
  padding: 16px;
}

.title {
  --circle-size: 10px;
  --text-margin: 10px;
  --left: 3px;

  position: relative;

  @include fonts.london2;

  text-indent: calc(var(--left) + var(--circle-size) + var(--text-margin))
}

.title::before,
.title::after {
  display: block;
  content: "";
  position: absolute;
  border-radius: 50%;
  left: var(--left);
  width: var(--circle-size);
  height: var(--circle-size);
  top: calc(var(--circle-size) / 2);
  box-sizing: border-box;
}

.title::before {
  animation: ringGrow 1.4s infinite ease-in-out;
  border: 1px solid colours.$amber;
  opacity: 0.65;
}

@media (prefers-reduced-motion) {
  .title::before {
    animation: none;
  }
}

.title::after {
  background-color: colours.$amber;
}

.bodyText {
  @include fonts.leavesden3;
}

@-webkit-keyframes ringGrow {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}
