@use "~styles/_styleGuideFont" as fonts;

button {
  &.sustainabilityInfoButton {
    text-decoration: underline;
    text-transform: none;
    text-align: left;
  }

  &.hideButton {
    visibility: hidden;
  }
}

