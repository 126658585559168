@use "~styles/_viewport" as viewports;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideButton";
@use "~styles/_utils";

html[data-pdp-in-stock="false"] {
  .container {
    display: none;
  }
}

.container {
  margin-top: 24px;
  width: 100%;

  h2 {
    padding-right: 36px;
  }
  
  h2 > button {
    @extend %london3-button;

    background-color: inherit;
    border: 0;
    font-family: inherit;
    font-weight: 700;
    padding: 14px 0;
    text-align: left;
  }

  @media (max-width: viewports.$breakpointMobileMaxWidth) {
    > ul {
      margin: 0 -10px;

      > li {
        padding: 0 10px;

        &:last-of-type {
          border-bottom-width: 0;
        }
      }
    }
  }

  [class*="accordion-item-module_contentWrapper__"] {
    @extend %overflowHiddenWithFocusShadowPadding;
  }
}

.link {
  @extend %linkButton;
  @extend %leavesden2;
}

.content {
  line-height: 1.4;

  ul {
    margin-top: 16px;
  }

  li {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 8px;
  }

  a {
    color: inherit;
    border-bottom: 1px solid colours.$black;
  }

  h3 {
    @extend %london3;
  }

  h3 + p {
    margin-top: 12px;
  }
}

.content + * {
  margin-top: 12px;
}

.productCode {
  margin-top: 16px;
}

.badges {
  margin: 2px 0 0;
  padding: 0;

  li {
    margin: 0 20px 0 0;
    padding: 0;
    list-style: none;

    img {
      max-width: 60px;
    }

    a {
      border: 0;
      display: inline-block;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
