@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.fitInfoContainer {
  @extend %leavesden4;

  display: flex;
  position: relative;
  margin-top: 16px;
  align-items: top;
  padding: 12px 16px 12px 40px;
  background-color: colours.$grey20;

  --ratingBarBackgroundColor: white;
  --ratingBarHeight: 8px;
  --ratingBarWidth: 16px;
}

.fitInfoLabel {
  width: 80px;
  display: inline-flex;
  margin-right: 10px;

  h2 {
    @extend %leavesden4;

    text-transform: unset;
    margin-top: -2px;
  }
}

.fitInfoBar {
  flex: 1;
}

.icon {
  transform: scale(0.7);
  position: absolute;
  top: -3px;
  left: 2px;
}

