.facetCarouselContainer {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-bottom: 10px;
}

.navigation {
  padding-top: 8px;

  > :first-child {
    padding-left: 10px;
    max-width: calc(100% - 10px);
  }
}
