@use "~styles/_loadingAnimation";
@use "~styles/_styleGuideColour" as colours;

.variantSelectorSkeleton,
.addToBagSkeleton,
.saveForLaterSkeleton {
  @extend %loadingAnimation;

  background-color: colours.$grey20;
}

.actionsSkeleton {
  margin-top: 20px;
  display: flex;
  position: relative;
}

.variantSelectorSkeleton {
  height: 40px;
  width: 100%;
  margin-top: 9px;
}

.addToBagSkeleton {
  width: calc(100% - 56px);
  min-height: 44px;
}

.saveForLaterSkeleton {
  border-radius: 100px;
  position: absolute;
  bottom: 2px;
  right: 0;
  height: 40px;
  width: 40px;
}

.container {
  margin-top: 100px;
}
