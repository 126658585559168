@use "~styles/_styleGuideFont";

.container {
  // Reset for consistent spacing across all clients
  *,
  ::before,
  ::after {
    margin: 0;
    padding: 0;
  }

  $space: 16px;

  padding: $space;

  hr,
  h4,
  h4 + p {
    margin-top: $space;
  }

  hr {
    margin-inline: -$space;
  }

  h3 {
    @extend %london2;
  }

  h4 {
    @extend %london3;
  }

  p {
    @extend %leavesden3;
  }

  .images {
    margin-top: 16px;

    > img {
      margin-right: 8px;
      max-width: 56px;
    }
  }
}
