@use "~styles/_styleGuideButton";
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_viewport.scss" as viewports;

.ingredientsButton {
  @extend %button;
  @extend %london3;

  align-items: center;
  background-color: transparent;
  border: none;
  border-top: 8px solid colours.$grey20;
  color: colours.$black;
  display: flex;
  padding: 0 10px;
  text-align: left;
  text-transform: unset;
  width: calc(100% + 20px);
  margin: 0 -10px;

  span:last-of-type {
    flex: 1;
    line-height: 1;
    padding: 16px;
  }

  @media(min-width: viewports.$breakpointMediumScreen) {
    border-top: 1px solid colours.$grey20;
    border-bottom: 1px solid colours.$grey20;
    padding: 0;
    margin-top: 20px;
    width: 100%;
  }
}
