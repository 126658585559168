@use "~styles/_styleGuideColour" as colours;

.tile {
  display: inline-block;

  > :first-child {
    border: 2px solid transparent;
    padding: 1px;
  }

  &.side-scroll {
    margin-right: 4px;
  }

  &.grid {
    > :first-child {
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  &.selected {
    > :first-child {
      border-color: colours.$black;
    }

    :focus-within {
      border-color: colours.$blue;
    }
  }

  &:not(.selected) {
    > :first-child {
      &:hover, &:focus, &:focus-within {
        border-color: colours.$blue;
        box-shadow: none;
        outline: none;
      }
    }
  }
}
