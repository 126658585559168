@use "~styles/_styleGuideColour" as colours;

$small-image-width: 45px;
$small-image-height: 57px;
$large-image-width: 56px;

.smallImage {
  height: $small-image-height;
  width: $small-image-width;
}

.largeImage  {
  height: 72px;
  width: $large-image-width;
}

.oosOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(colours.$black, 0.52);

  .oosOverlayLine {
    width: 100%;
    height: 100%;
    border-bottom: 4px solid white;
    transform: translateY(-50%) skewY(-51.71deg);
  }
}

.facetThumbnailLink {
  display: flex;
  position: relative;

  &:not(.isCarousel .facetThumbnailLink) {
    box-shadow: none;
    overflow:hidden;
  }
}

.facetThumbnailLinkDisabled {
  cursor: default;
}