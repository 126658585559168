@use "~styles/_styleGuideFont" as *;
@use "~styles/_styleGuideColour" as colours;

.releaseDateBanner {
  @extend %london4;

  color: colours.$white;
  padding: 8px 12px;
  text-align: center;
  text-transform: uppercase;

  &.access-view {
    background-color: colours.$fullBlack;
  }

  &.access-purchase {
    background-color: colours.$asosWorldPurpleMain;
  }

  .date {
    white-space: nowrap;
  }
}
