@use "~styles/_loadingAnimation";
@use "~styles/viewport" as viewports;

.skeleton {
  @extend %loadingAnimation;

  height: 25px;
  width: 100px;
  margin-top: 12px;
}
