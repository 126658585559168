.eight > *:not(:empty) {
  margin-top: 8px;
}

.twelve > *:not(:empty) {
  margin-top: 12px;
}

.sixteen > *:not(:empty) {
  margin-top: 16px;
}

.twenty > *:not(:empty) {
  margin-top: 20px;
}

.twentyfour > *:not(:empty) {
  margin-top: 24px;
}

.excludeFirst > *:first-child {
  margin-top: 0;
}